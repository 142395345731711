<template>
  <div style="width: 100% !important">
    <v-pagination v-model="page" :length="maxPage" v-if="items.length > 0" />
    <div v-for="(item, i) in items" :key="'paginated-' + i">
      <slot :index="i" :item="item"></slot>
    </div>
    <v-pagination v-model="page" :length="maxPage" v-if="items.length > 0" />
  </div>
</template>

<script>
export default {
  name: "Paginated",

  props: {
    initialPage: {
      type: Number,
      default: 1,
    },

    initialItems: {
      type: Array,
      default: () => [],
    },

    pageSize: {
      type: Number,
      default: 25,
    },

    totalLength: {
      type: Number,
      default: 0,
      required: true,
    },

    newPageFunction: {
      type: Function,
      required: true,
    },
  },

  computed: {
    maxPage() {
      return Math.ceil(this.totalLength / this.pageSize);
    },
  },

  data() {
    return {
      page: this.initialPage,
      items: [],
    };
  },

  async created() {
    this.items = this.initialItems;
  },

  watch: {
    page: {
      handler: async function (newPage) {
        this.items = await this.newPageFunction(newPage, this.pageSize);
      },
      immediate: true,
    },
  },
};
</script>
