<template>
  <v-container fluid>
    <v-switch v-model="compact" label="Compact" />
    <v-expansion-panels>
      <paginated
        v-if="items.length > 0"
        :initialItems="items"
        :totalLength="totalLength"
        :newPageFunction="getAuditLog"
        :page-size="pageSize"
      >
        <template v-slot:default="{ item }">
          <audit-log-item-compact v-if="compact" :item="item" />
          <audit-log-item v-else :item="item" />
        </template>
      </paginated>
      <h3 v-else>N/A</h3>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import AuditLogRepository from "@/api/repositories/auditLogRepository";
import Paginated from "@/components/common/Paginated.vue";
import AuditLogItem from "./AuditLogItem.vue";
import AuditLogItemCompact from "./AuditLogItemCompact.vue";
import syntaxHighlightMixin from "../../_helpers/syntaxHightlightingMixin.js";

export default {
  name: "AuditLogList",

  mixins: [syntaxHighlightMixin],

  props: {
    isUser: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Paginated,
    AuditLogItem,
    AuditLogItemCompact,
  },

  data() {
    return {
      compact: true,
      items: [],
      totalLength: 0,
      pageSize: 10,
    };
  },

  async created() {
    const data = await this.fetch(1, this.pageSize);
    this.items = data.items;
    this.totalLength = data.totalCount;
  },

  methods: {
    async fetch(page, pageSize) {
      if (!this.isUser) {
        const { data } = await AuditLogRepository.getByCompany(
          this.$store.getters["users/companyId"],
          page,
          pageSize
        );
        return data;
      }

      const { data } = await AuditLogRepository.getByUser(
        this.$route.params.id,
        page,
        pageSize
      );

      return data;
    },

    async getAuditLog(page, pageSize) {
      return (await this.fetch(page, pageSize))?.items || [];
    },
  },
};
</script>
