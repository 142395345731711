import Repository from "../Repository";
import { apiConfig } from "../../_helpers/apiHelper";

const resource = "/AuditLog";

export default {
  getByUser(userId, page, pageSize) {
    if (page == 0) page = 1;

    return Repository.get(
      `${resource}/user/${userId}/${page}/${pageSize}`,
      apiConfig()
    );
  },

  getByCompany(companyId, page, pageSize) {
    if (page == 0) page = 1;

    return Repository.get(
      `${resource}/company/${companyId}/${page}/${pageSize}`,
      apiConfig()
    );
  },
};
