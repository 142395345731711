<template>
  <v-expansion-panel>
    <v-expansion-panel-header hide-actions class="pa-0 mb-1">
      <v-card style="border: 1px solid lightgray">
        <v-card-title
          class="subtitle-1 font-weight-bold light-blue lighten-5 py-2 black--text"
        >
          <div class="d-flex flex-column align-start">
            <div class="d-flex align-items-center">
              <v-icon left small color="blue darken-2">mdi-account</v-icon>
              <span>{{ item.userFullName }}</span>
            </div>
            <div class="d-flex align-items-center mt-1">
              <v-icon left small color="grey">mdi-clock-outline</v-icon>
              <span class="caption grey--text ml-1">
                {{
                  `${$t("tag.log.journal.createdAt")}: ${new Date(
                    item.createdAt
                  ).toLocaleString()}`
                }}
              </span>
            </div>
          </div>
        </v-card-title>

        <v-card-text class="pt-2">
          {{ item.message }}
        </v-card-text>
      </v-card>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-container fluid v-if="item.objectFrom || item.objectTo">
        <v-row class="text-left">
          <v-col cols="12" sm="6" md="6">
            <h3 class="subtitle-1 font-weight-bold">
              {{ $t("auditLog.from") }}
            </h3>
            <pre
              style="overflow-x: auto"
              v-html="
                item.objectFrom
                  ? syntaxHighlight(
                      JSON.stringify(JSON.parse(item.objectFrom), undefined, 2)
                    )
                  : ''
              "
            ></pre>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <h3 class="subtitle-1 font-weight-bold">
              {{ $t("auditLog.to") }}
            </h3>
            <pre
              style="overflow-x: auto"
              v-html="
                item.objectTo
                  ? syntaxHighlight(
                      JSON.stringify(JSON.parse(item.objectTo), undefined, 2)
                    )
                  : ''
              "
            ></pre>
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import syntaxHighlightMixin from "../../_helpers/syntaxHightlightingMixin.js";
export default {
  name: "AuditLogItem",
  mixins: [syntaxHighlightMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
