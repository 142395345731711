var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-0 mb-1",attrs:{"hide-actions":""}},[_c('v-card',{staticStyle:{"border":"1px solid lightgray"}},[_c('v-card-title',{staticClass:"subtitle-2 font-weight-bold light-blue lighten-5 py-1 black--text"},[_c('div',{staticClass:"d-flex justify-start align-start"},[_c('div',{staticClass:"d-flex no-wrap align-start justify-space-between",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-column align-start mr-4"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-icon',{attrs:{"left":"","small":"","color":"blue darken-2"}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.item.userFullName)+" ")],1),_c('div',{staticClass:"d-flex align-items-center justify-center align-center"},[_c('v-icon',{attrs:{"left":"","small":"","color":"grey"}},[_vm._v("mdi-clock-outline")]),_c('span',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(`${_vm.$t("tag.log.journal.createdAt")}: ${new Date( _vm.item.createdAt ).toLocaleString()}`)+" ")])],1)]),_c('div',{staticClass:"d-flex align-items-center mt-1"},[_c('v-icon',{attrs:{"left":"","small":"","color":"grey"}},[_vm._v("mdi-text")]),_c('span',{staticClass:"caption grey--text ml-1"},[_vm._v(" "+_vm._s(_vm.item.message)+" ")])],1)])])])],1)],1),_c('v-expansion-panel-content',[(_vm.item.objectFrom || _vm.item.objectTo)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-left"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h3',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("auditLog.from"))+" ")]),_c('pre',{staticStyle:{"overflow-x":"auto"},domProps:{"innerHTML":_vm._s(
              _vm.item.objectFrom
                ? _vm.syntaxHighlight(
                    JSON.stringify(JSON.parse(_vm.item.objectFrom), undefined, 2)
                  )
                : ''
            )}})]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h3',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("auditLog.to"))+" ")]),_c('pre',{staticStyle:{"overflow-x":"auto"},domProps:{"innerHTML":_vm._s(
              _vm.item.objectTo
                ? _vm.syntaxHighlight(
                    JSON.stringify(JSON.parse(_vm.item.objectTo), undefined, 2)
                  )
                : ''
            )}})])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }